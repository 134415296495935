@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Open Sans', sans-serif;
}
.railway {
  font-family: 'Open Sans', sans-serif;
}
.header{
  background-image: url("../src/assets/images/105.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: cover;
  height: 100vh;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #000;
  color: #fff;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: #000;
  color: #fff;
  font-family: "Special Elite", cursive !important;
}
.ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  background-color: #000;
  color: #fff;
  font-family: "Special Elite", cursive !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px 12px 0px;
}
.navColor{
  background-color: rgba(0, 0, 0, 0.8);
}
.carImage {
  width: 700px;
}
.vertical-timeline.vertical-timeline--two-columns {
  width: 100% !important;
}
.vertical-timeline img {
  padding: 2px !important;
  margin-top: 3px !important;
}
.card-overlay{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}
@media only screen and (max-width: 768px) {
  .carImage {
    width: 100%;
  }
}
